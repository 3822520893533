import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`CrossFit Open WOD 19.4`}</strong></p>
    <p>{`3 rounds of:`}</p>
    <p>{`10-Snatches (95/65)`}</p>
    <p>{`12-Bar Facing Burpees`}</p>
    <p>{`Then, rest 3:00 before continuing with:`}</p>
    <p>{`3 rounds of:`}</p>
    <p>{`10-Bar Muscle Ups`}</p>
    <p>{`12-Bar Facing Burpees`}</p>
    <p>{`Time cap: 12 minutes, including 3-minute rest period.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`We’ll be judging 19.4 at each class and throwing down again tonight
from 4:30-6:30!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our next Mobility class will from from 11:00-11:30 Saturday, March
16th.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our new class schedule will start next Monday, March 17th along
with our new Ville Bootcamp Tues-Friday at 9:30am!  Evening CrossFit
classes will now be offered Monday-Friday at 4:00, 5:00 & 6:00pm!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      